/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Od połowy 2022 roku trwała inwestycja pn. „Rozbudowa i wzmocnienie drogi krajowej nr 62 na odcinku Strzelno – Kobylniki”. Remontowany odcinek objął 10 km jej długości wraz z poszerzeniem drogi do 7 metrów."), "\n", React.createElement(_components.p, null, "W ramach inwestycji zrealizowane zostaną następujące zadania:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "korekta niwelety drogi, łuków,"), "\n", React.createElement(_components.li, null, "przebudowa skrzyżowań wraz z wykonaniem lewoskrętów,"), "\n", React.createElement(_components.li, null, "budowa ronda na skrzyżowaniu z drogą powiatową nr 2448C w m. Stodólno,"), "\n", React.createElement(_components.li, null, "budowa chodników, oświetlenia i zatok autobusowych."), "\n", React.createElement(_components.li, null, "częściowe poprowadzenie drogi nowym śladem (obwodnice miejscowości Stodólno i Sławsk Wielki)"), "\n", React.createElement(_components.li, null, "nasadzenie ponad 1300 drzew i ponad 5000 krzewów."), "\n"), "\n", React.createElement(_components.p, null, "Inwestycję oddano do użytku w czerwcu 2024 roku."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
